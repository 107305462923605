
/* Garmond */

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/Garmond/EBGaramond-BoldItalic.woff2') format('woff2'),
      url('../fonts/Garmond/EBGaramond-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/Garmond/EBGaramond-ExtraBold.woff2') format('woff2'),
      url('../fonts/Garmond/EBGaramond-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/Garmond/EBGaramond-Bold.woff2') format('woff2'),
      url('../fonts/Garmond/EBGaramond-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/Garmond/EBGaramond-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/Garmond/EBGaramond-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/Garmond/EBGaramond-Italic.woff2') format('woff2'),
      url('../fonts/Garmond/EBGaramond-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/Garmond/EBGaramond-MediumItalic.woff2') format('woff2'),
      url('../fonts/Garmond/EBGaramond-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/Garmond/EBGaramond-Medium.woff2') format('woff2'),
      url('../fonts/Garmond/EBGaramond-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/Garmond/EBGaramond-Regular.woff2') format('woff2'),
      url('../fonts/Garmond/EBGaramond-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/Garmond/EBGaramond-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/Garmond/EBGaramond-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/Garmond/EBGaramond-SemiBold.woff2') format('woff2'),
      url('../fonts/Garmond/EBGaramond-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* MONT */

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Black.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-BlackItalic.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-ExtraLightItalic.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-LightItalic.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Italic.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Thin.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-ThinItalic.woff2') format('woff2'),
      url('../fonts/Montserrat/Montserrat-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

